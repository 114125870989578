.contact-icons {
  text-align: center;
  color: white;
  margin-top: 80px;
}

.contact-section {
  font-weight: bold;
}

.contact-icon {
  margin-left: 2em;
  margin-right: 2em;
  height: 4em;
  width: 4em;
  border-radius: 10px;
  border: 1px solid rgb(255, 55, 155);
}

p {
  margin-left: 2.6em;
  margin-right: 2.6em;
}

.names {
  display: flex;
  color: white;
  flex-direction: row;
  justify-content: center;
}