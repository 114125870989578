.home {
  text-align: center;
  color: white;
  font-weight: bold;
}

.home-sections {
  margin-top: 1.5em;
}

.home-contact-icon {
  margin-left: .8em;
  margin-right: .8em;
  height: 2.5em;
  width: 2.5em;
  border-radius: 10px;
  border: 1px solid rgb(255, 55, 155);
}

.project-icon {
  height: 10em;
  width: 10em;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  border-radius: 10px;
  border: 1px solid purple;
}

h3 {
  margin-left: 5em;
  margin-right: 5em;
  margin-bottom: 10px;
}

.stack-images {
  margin: 10px;
}

.stack-icon {
  margin-left: .8em;
  margin-right: .8em;
  height: 2.5em;
  width: 2.5em;
  border-radius: 10px;
  border: 1px solid rgb(6, 6, 245);
}

/*All the new stuff*/

/*
This demo was very old and overly complex
I have updated it with a cleaner, more modern technique
It still uses mix-blend-modes, so the basic idea hasn't changed

Original
https://codepen.io/giana/pen/MWxONWm
*/

/* Create pseudo elements for both elements */
.text-effect-wrapper,
.text {

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 100px;
    inset: 0;
    pointer-events: none;
  }
}

.text-effect-wrapper {
  /* Can be anything light-colored */
  --spotlight-color: white;

  overflow: hidden;
  position: relative;
  /* added by Claire post-copy to avoid nav bar and not leave shiny trace */
  margin-top: 60px;

  /* Shimmer animation */
  &::before {
    animation: shimmer 15s infinite linear;
    background:
      radial-gradient(circle, var(--spotlight-color), transparent 25%) 0 0 / 25% 25%,
      radial-gradient(circle, var(--spotlight-color), black 25%) 50% 50% / 12.5% 12.5%;
    inset-block-start: -100%;
    inset-inline-start: -100%;
    mix-blend-mode: color-dodge;
    z-index: 3;
  }

  /* Extra filter to boost colors and contrast */
  &::after {
    backdrop-filter: blur(1px) brightness(90%) contrast(150%);
    z-index: 4;
  }
}

@keyframes shimmer {
  100% {
    transform: translate3d(50%, 50%, 0);
  }
}

.text {
  /* Mask colors */
  /* Should be black and white */
  --background-color: black;
  --text-color: white;

  /* Text color */
  /* Can be anything */
  --color-1: red;
  --color-2: blue;

  /* Fuzzy white outline text */
  color: transparent;
  text-shadow:
    0 0 0.02em var(--background-color),
    0 0 0.02em var(--text-color),
    0 0 0.02em var(--text-color),
    0 0 0.02em var(--text-color);

  /* Improve contrast of outline */
  &::before {
    backdrop-filter: blur(0.013em) brightness(400%);
    z-index: 1;
  }

  /* Add text color */
  &::after {
    background: linear-gradient(45deg, var(--color-1), var(--color-2));
    mix-blend-mode: multiply;
    z-index: 2;
  }
}

/* Alternative styling */
body:has(#option-toggle:checked) {
  & .text-effect-wrapper {
    --spotlight-color: orange;

    &::after {
      backdrop-filter: brightness(90%) contrast(150%);
    }
  }

  & .text {
    --angle: 5deg;
    --color-1: hsl(163, 100%, 51%);
    --color-2: hsl(295, 88%, 32%);
    --color-3: hsl(59, 100%, 50%);

    text-shadow:
      0 0 0.03em var(--background-color),
      0 0 0.03em var(--text-color);

    &::before {
      backdrop-filter: brightness(150%) contrast(200%);
    }

    &::after {
      background: linear-gradient(var(--angle), var(--color-1), var(--color-2), var(--color-3));
      mix-blend-mode: color-dodge;
    }
  }
}

/* === Pen styling, ignore */

h1 {
  --font-size: clamp(3rem, 3.25rem + 15vw, 5rem);

  font: 600 var(--font-size)/1 "Lato", sans-serif;
  text-transform: uppercase;
  text-align: center;

  &:empty,
  &:focus {
    border: 2px dotted white;
    min-width: 1ch;
    outline-offset: 7px;
  }
}

body {
  background: black;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
  align-items: center;
}

label {
  background-color: hsl(240deg, 20%, 50%);
  border-radius: 5px;
  color: #fff;
  padding: 0.5em 1em;

  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;

  &:has(:checked) {
    background-color: hsl(350deg, 60%, 50%);
  }
}

input {
  position: absolute;
  opacity: 0;
}